import Vue from 'vue';
import VueRouter from 'vue-router';
import BaseLayout from '../layouts/BaseLayout.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/org',
    name: 'Org',
    meta: {
      title: '组织管理'
    },
    component: BaseLayout,
    children: [
      {
        path: '/org/menu',
        name: 'Menu',
        meta: {
          title: '菜单管理'
        },
        component: () => import(/* webpackChunkName: "menu" */ '@/views/menu/MenuList.vue')
      },
      {
        path: '/org/department',
        name: 'Department',
        meta: {
          title: '部门管理'
        },
        component: () =>
          import(/* webpackChunkName: "department" */ '@/views/department/Department.vue')
      },
      {
        path: '/org/position',
        name: 'Position',
        meta: {
          title: '岗位管理'
        },
        component: () =>
          import(/* webpackChunkName: "position" */ '@/views/position/PositionList.vue')
      },
      {
        path: '/org/role',
        name: 'RoleList',
        meta: {
          title: '角色管理'
        },
        component: () => import(/* webpackChunkName: "roleList" */ '@/views/role/RoleList.vue')
      },
      {
        path: '/org/grade',
        name: 'grade',
        meta: {
          title: '职级管理'
        },
        component: () => import(/* webpackChunkName: "grade" */ '@/views/grade/GradeList.vue')
      },
      {
        path: '/org/log',
        name: 'log',
        meta: {
          title: '日志管理'
        },
        component: () => import(/* webpackChunkName: "log" */ '@/views/log/LogList.vue')
      }
    ]
  },

  {
    path: '/work',
    name: 'Work',
    meta: {
      title: '业务配置'
    },
    component: BaseLayout,
    children: [
      {
        path: '/work/channel',
        name: 'Channel',
        meta: {
          title: '渠道管理'
        },
        component: () => import(/* webpackChunkName: "channel" */ '@/views/channel/index.vue')
      },
      {
        path: '/work/sensitive/words',
        name: 'SensitiveWords',
        meta: {
          title: '敏感词管理'
        },
        component: () =>
          import(
            /* webpackChunkName: "sensitive-words" */
            '@/views/sensitive-words/SensitiveWordList.vue'
          )
      },
      {
        path: '/work/dictionary',
        name: 'Dictionary',
        meta: {
          title: '数据字典'
        },
        component: () => import(/* webpackChunkName: "dictionary" */ '@/views/dictionary/List.vue')
      },
      {
        path: '/work/order/config',
        name: 'OrderConfig',
        meta: {
          title: '订单配置'
        },
        component: () =>
          import(/* webpackChunkName: "order-config" */ '@/views/order-config/Index.vue')
      },
      {
        path: '/work/phone/config',
        name: 'PhoneConfig',
        meta: {
          title: '平台电话配置'
        },
        component: () =>
          import(/* webpackChunkName: "phone-config" */ '@/views/phone-config/PhoneConfig.vue')
      }
    ]
  },
  {
    path: '/small/program',
    name: 'SmallProgram',
    meta: {
      title: '小程序管理'
    },
    component: BaseLayout,
    children: [
      {
        path: '/small/program/home',
        name: 'SmallProgramHome',
        meta: {
          title: '首页内容管理'
        },
        component: () =>
          import(
            /* webpackChunkName: "small-index-config" */
            '@/views/small-program/home-content/IndexConfig.vue'
          )
      },
      {
        path: '/small/program/user/score',
        name: 'SmallProgramUserScore',
        meta: {
          title: '用户评分'
        },
        component: () =>
          import(/* webpackChunkName: "user-score" */ '@/views/small-program/user-score/Index.vue')
      },
      {
        path: '/small/program/advice',
        name: 'SmallProgramAdvice',
        meta: {
          title: '意见反馈'
        },
        component: BaseLayout,
        children: [
          {
            path: '/small/program/advice/list',
            name: 'SmallProgramAdviceList',
            meta: {
              title: '意见反馈列表'
            },
            component: () =>
              import(
                /* webpackChunkName: "program-advice-list" */
                '@/views/small-program/advice/AdviceList.vue'
              )
          },
          {
            path: '/small/program/advice/detail',
            name: 'SmallProgramAdviceDetail',
            meta: {
              title: '意见反馈详情'
            },
            component: () =>
              import(
                /* webpackChunkName: "small-advice-detail" */
                '@/views/small-program/advice/AdviceDetail.vue'
              )
          }
        ]
      },
      {
        path: '/small/program/agreement',
        name: 'SmallProgramAgreement',
        meta: {
          title: '用户协议管理'
        },
        component: () =>
          import(/* webpackChunkName: "agree-user" */ '@/views/small-program/agreement/index.vue')
      },
      {
        path: '/small/program/agreement/detail',
        name: 'SmallProgramAgreementDetail',
        meta: {
          title: '协议详情'
        },
        component: () =>
          import(/* webpackChunkName: "agree-user" */ '@/views/small-program/agreement/Detail.vue')
      },
      {
        path: '/small/program/businessDesc',
        name: 'SmallProgrambusinessDesc',
        meta: {
          title: '业务说明管理'
        },
        component: () =>
          import(
            /* webpackChunkName: "agree-user" */
            '@/views/small-program/business-desc/index.vue'
          )
      },
      {
        path: '/small/program/mediaAdvertisement',
        name: 'MediaAdvertisement',
        meta: {
          title: '影像馆广告配置'
        },
        component: () => import('@/views/small-program/mediaAdvertisement/index.vue')
      }
    ]
  },
  {
    path: '/small/jpgProgram',
    name: 'SmallJpgProgram',
    meta: {
      title: '小程序管理'
    },
    component: BaseLayout,
    children: [
      {
        path: '/small/jpgProgram/home',
        name: 'SmallJpgProgramHome',
        meta: {
          title: '首页内容管理'
        },
        component: () =>
          import(
            /* webpackChunkName: "small-index-config" */
            '@/views/small-program-jpg/home-content/IndexConfig.vue'
          )
      },
      {
        path: '/small/jpgProgram/user/score',
        name: 'SmallJpgProgramUserScore',
        meta: {
          title: '用户评分'
        },
        component: () =>
          import(/* webpackChunkName: "user-score" */ '@/views/small-program-jpg/user-score/Index.vue')
      },
      {
        path: '/small/jpgProgram/advice',
        name: 'SmallJpgProgramAdvice',
        meta: {
          title: '意见反馈'
        },
        component: BaseLayout,
        children: [
          {
            path: '/small/jpgProgram/advice/list',
            name: 'SmallJpgProgramAdviceList',
            meta: {
              title: '意见反馈列表'
            },
            component: () =>
              import(
                /* webpackChunkName: "program-advice-list" */
                '@/views/small-program-jpg/advice/AdviceList.vue'
              )
          },
          {
            path: '/small/jpgProgram/advice/detail',
            name: 'SmallJpgProgramAdviceDetail',
            meta: {
              title: '意见反馈详情'
            },
            component: () =>
              import(
                /* webpackChunkName: "small-advice-detail" */
                '@/views/small-program-jpg/advice/AdviceDetail.vue'
              )
          }
        ]
      },
      {
        path: '/small/jpgProgram/agreement',
        name: 'SmallJpgProgramAgreement',
        meta: {
          title: '用户协议管理'
        },
        component: () =>
          import(/* webpackChunkName: "agree-user" */ '@/views/small-program-jpg/agreement/index.vue')
      },
      {
        path: '/small/jpgProgram/agreement/detail',
        name: 'SmallJpgProgramAgreementDetail',
        meta: {
          title: '协议详情'
        },
        component: () =>
          import(/* webpackChunkName: "agree-user" */ '@/views/small-program-jpg/agreement/Detail.vue')
      },
      {
        path: '/small/jpgProgram/businessDesc',
        name: 'SmallJpgProgrambusinessDesc',
        meta: {
          title: '业务说明管理'
        },
        component: () =>
          import(
            /* webpackChunkName: "agree-user" */
            '@/views/small-program-jpg/business-desc/index.vue'
          )
      },
      {
        path: '/small/jpgProgram/mediaAdvertisement',
        name: 'JpgMediaAdvertisement',
        meta: {
          title: '影像馆广告配置'
        },
        component: () => import('@/views/small-program-jpg/mediaAdvertisement/index.vue')
      }
    ]
  },
  {
    path: '/smsShareOut',
    name: 'smsShareOut',
    meta: { title: '短信营销管理' },
    component: BaseLayout,
    children: [
      {
        path: 'list',
        name: 'smsShareOutList',
        meta: { title: '短信营销列表' },
        component: () => import('@/views/smsShareOut/index.vue')
      }
    ]
  },
  {
    path: '/miniprogramProducts',
    name: 'miniprogramProducts',
    meta: { title: '小程序产品页面管理' },
    component: BaseLayout,
    children: [
      {
        path: 'config',
        name: 'miniprogramProductsConfig',
        meta: { title: '小程序产品页面配置管理' },
        component: () => import('@/views/miniprogram-products/index.vue')
      }
    ]
  }
];

const router = new VueRouter({
  mode: 'history',
  // 为微应用路由加上前缀，如果不加微应用路由切换会有问题
  // eslint-disable-next-line no-underscore-dangle
  base: window.__POWERED_BY_QIANKUN__ ? '/index/system' : process.env.BASE_URL,
  routes
});

export default router;
